<template>
    <BottomModal
        :is-visible="modalStore?.activeModal === MODAL_REGISTER"
        class="register-modal"
        title="Create an account"
        description="Access all MedPeers content"
        @close="modalStore?.closeModal"
    >
        <form
            novalidate
            class="register-modal__form"
            @submit.prevent="onSubmit"
        >
            <BaseFormField
                required
                :errors="errors.email ? [errors.email] : []"
                :data-has-warning="emailInBlacklist(email) && !errors.email"
            >
                <template #label>
                    <label for="email">Your work email</label>
                </template>
                <template #description>
                    We use your work email to verify your connection to a trusted institute.
                </template>
                <BaseInput
                    id="email"
                    v-model="email"
                    type="email"
                    name="email"
                    autocomplete="email"
                    required
                />

                <div
                    v-if="emailInBlacklist(email) && !errors.email"
                    class="dn-form-field__warning"
                >
                    Please use an email that belongs to a medical institute if you can. This helps us to verify your account.
                </div>
            </BaseFormField>

            <BaseFormField
                required
                :errors="errors.firstName ? [errors.firstName] : []"
            >
                <template #label>
                    <label for="firstName">First name</label>
                </template>
                <BaseInput
                    id="firstName"
                    v-model.lazy="firstName"
                    type="text"
                    name="firstName"
                    autocomplete="given-name"
                    required
                />
            </BaseFormField>

            <BaseFormField
                required
                :errors="errors.lastName ? [errors.lastName] : []"
            >
                <template #label>
                    <label for="lastName">Last name</label>
                </template>
                <BaseInput
                    id="lastName"
                    v-model.lazy="lastName"
                    type="text"
                    name="lastName"
                    autocomplete="family-name"
                    required
                />
            </BaseFormField>

            <BaseFormField
                required
                :errors="errors.fieldsOfInterest ? [errors.fieldsOfInterest] : []"
            >
                <template #label>
                    <label for="fieldsOfInterest">Fields of interest</label>
                </template>

                <BaseSelect
                    id="fieldsOfInterest"
                    v-model="fieldsOfInterest"
                    class="base-select__specialisations"
                    name="fieldsOfInterest"
                    :options="specialisationOptions"
                    :is-multiselect="true"
                    :deselect-sr-label="'deselect'"
                    :selected-sr-label="'select'"
                    :toggle-sr-label="'toggle'"
                />
            </BaseFormField>

            <BaseFormField
                required
                :errors="errors.isMedicalProfessional ? [errors.isMedicalProfessional] : []"
                style="margin-bottom: .3125rem;"
            >
                <BaseCheckbox
                    v-model="isMedicalProfessional"
                    style="display: inline-block;"
                    required
                >
                    I am a medical professional*
                </BaseCheckbox>
                <InfoIcon>
                    You are a licensed medical professional or a registered student in the field of healthcare.
                </InfoIcon>
            </BaseFormField>

            <BaseFormField
                required
                :errors="errors.hasAcceptedTerms ? [errors.hasAcceptedTerms] : []"
            >
                <BaseCheckbox
                    v-model="hasAcceptedTerms"
                    class="base-checkbox--terms"
                    required
                >
                    I accept the <a href="/legal" target="_blank">terms and conditions</a>*
                </BaseCheckbox>
            </BaseFormField>

            <div
                v-if="errorMessage"
                class="register-modal__form-error"
            >
                {{ errorMessage }}
            </div>

            <BaseButton
                :loading="loading"
                class="base-button--wide"
            >
                Create account
            </BaseButton>
        </form>

        <BaseButton
            class="base-button--content-only base-button--wide"
            @click="modalStore.toggleModal(MODAL_LOGIN)"
        >
            Already member? Login
        </BaseButton>
    </BottomModal>
</template>

<script setup>
import SignupMutation from '~/graphql/mutations/signup.graphql';
import { useForm, configure } from 'vee-validate';
import { object, string, array, boolean } from 'yup';
import { useModalStore, MODAL_REGISTER, MODAL_LOGIN, MODAL_ACCESS_REQUESTED } from '~/store/modal';
import { useAuthStore } from '~/store/auth';

const modalStore = useModalStore();
const authStore = useAuthStore();
const { tempEmail, verificationSent } = storeToRefs(authStore);
const { execute } = useAsyncMutation('register');
const { push } = useTagmanager();

const errorMessage = ref('');
const loading = ref(false);

const { data: specialisationsResponse } = await useAsyncRestApi('specialisations/options');

const specialisationOptions = computed(() => {
    return toValue(specialisationsResponse)?.data ?? [];
});

configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: false,
    validateOnModelUpdate: false,
});

const emailInBlacklist = (emailValue) => {
    if (!emailValue) {
        return false;
    }

    const emailParts = emailValue.split('@');
    const domain = emailParts?.[1]?.split('.')?.[0];
    return blackListEmailProviders.includes(domain);
};

const blackListEmailProviders = [
    'gmail',
    'yahoo',
    'hotmail',
    'outlook',
    'icloud',
];

const validationSchema = object({
    email: string().required('Email address is required').email('This is not a valid email address'),
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    fieldsOfInterest: array().min(1, 'Please select at least one field of interest'),
    isMedicalProfessional: boolean().oneOf([true], 'Please select if you are a medical professional').required('Please select if you are a medical professional'),
    hasAcceptedTerms: boolean().oneOf([true], 'Please accept the terms and conditions').required('Please accept the terms and conditions')
});

const { 
    errors,
    setFieldError,
    defineField,
    handleSubmit,
    setFieldValue
} = useForm({
    validationSchema,
    initialValues: {
        fieldsOfInterest: []
    }
});

const [email] = defineField('email');
const [firstName] = defineField('firstName');
const [lastName] = defineField('lastName');
const [fieldsOfInterest] = defineField('fieldsOfInterest');
const [isMedicalProfessional] = defineField('isMedicalProfessional', {
    validateOnModelUpdate: true
});
const [hasAcceptedTerms] = defineField('hasAcceptedTerms', {
    validateOnModelUpdate: true
});

const onSubmit = handleSubmit(async(values) => {
    errorMessage.value = '';
    loading.value = true;

    const { data, error } = await execute({
        mutation: SignupMutation,
        variables: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            fieldsOfInterest: values.fieldsOfInterest,
            isMedicalProfessional: values.isMedicalProfessional
        }
    });
    loading.value = false;

    if (error.value || !data.value?.data?.signup) {
        errorMessage.value = 'Something went wrong. Please try again later.';
        return;
    }

    const { signup: signupResponse } = data.value.data;

    if (signupResponse.status === 'ok') {
        if (signupResponse.message === 'NEEDS_VERIFICATION') {
            modalStore.toggleModal(MODAL_ACCESS_REQUESTED);
            return;
        }

        if (signupResponse.message === 'SUCCESS') {
            tempEmail.value = values.email;
            verificationSent.value = true;

            push({
                event: 'create_account_step_verification_code'
            });

            modalStore.toggleModal(MODAL_LOGIN);
        }
    } else if (signupResponse.status === 'error') {
        if (signupResponse.message === 'EMAIL_EXISTS') {
            setFieldError('email', 'A user with this email already exists, please log in');
        }
    }
});

watch(tempEmail, (newValue) => {
    setFieldValue('email', newValue);
});
</script>

<style lang="less">
.register-modal {
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: var(--spacing-xs);
    }

    .base-form-field {
        text-align: left;
    }

    .info-icon {
        display: inline-block;
        vertical-align: middle;
        transform: translateY(-5px);
        margin-left: 8px;
    }

    .register-modal__form-error {
        .typography-small();
        color: var(--color-highlight-error);
        margin-bottom: .5rem;
    }

    .base-select__specialisations .dn-select__list {
        max-height: 9rem;
        overflow-y: scroll;
        overflow-x: hidden;

        @media @q-md-min {
            max-height: 10rem;
        }
    }

    .base-checkbox--terms {
        .dn-checkbox__label {
            display: inline;
        }

        a {
            text-decoration: underline;
        }
    }
}
</style>
